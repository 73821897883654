import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandArrowsAlt } from "@fortawesome/free-solid-svg-icons";


export const GroupedBarChart = ({ questions }) => {
  const [isZoomed, setIsZoomed] = useState(false);
 let height = 400;
  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  // Group data by questionHeader
  const groupedQuestions = questions.reduce((acc, question) => {
    const key = question.questionHeader;
    if (!acc[key]) {
      acc[key] = {
        ...question,
        datasets: [],
      };
    }
    acc[key].datasets.push({
      filterValue: question.filterValue,
      data: question.datasets[0].data,
    });
    return acc;
  }, {});

  const chartQuestions = Object.values(groupedQuestions);

  console.log(chartQuestions,groupedQuestions);
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        justifyContent: "space-between",
      }}
    >
      {chartQuestions.map((question, questionIndex) => {
        // Prepare chart data for each question
        const chartData = question.datasets.map((dataset) => {
          const dataObject = { filter: dataset.filterValue }; // Use "Part Time" or "Full Time"
          question.labels.forEach((label, index) => {
            height = height + 10;
            dataObject[label] = dataset.data[index] || 0;
          });
          return dataObject;
        });

        const colors = ["#ff6699", "#4db8ff", "#ffcc33", "#007A7A"]; // Dynamic colors for labels
        const dynamicHeight =
        chartData.length * 50 + (question.labels.length>5 ? question.labels.length * 50 : question.labels.length * 60) + 300;
      
        return (
          <div
            key={questionIndex}
            style={{
              width: isZoomed ? "100%" : "49.5%",
              backgroundColor: "#fff",
              borderRadius: "0.0rem",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              padding: "10px",
              marginBottom: "10px",
              position: "relative",
              transition: "width 0.3s",
            }}
          >
            <div  style={{
          position: 'relative',

          overflowX: 'auto',
          overflowY: 'auto', // Enable vertical scroll
          maxHeight: '400px', // Set a maximum height for vertical scrolling
   
        }}>
            <h3 style={{ textAlign: "center" }}>
              {question.questionHeader}: {question.questionText}
            </h3>
            <ResponsiveContainer width="100%" height={dynamicHeight}>
              <BarChart
                layout="vertical"
                data={chartData}
                barSize={30}
                barGap={10}
                barCategoryGap={10}
                margin={{  left:40,  }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis
                  type="category"
                  dataKey="filter" // Display "Part Time" and "Full Time"
                  tickLine={false}
                  axisLine={false}
                  //width={150} // Ensure enough space for filter values
                />
                <Tooltip formatter={(value) => `${value}%`} />
                <Legend align="middle" verticalAlign="top"   wrapperStyle={{
                      paddingBottom: '10px',
                      lineHeight: '24px', // Adjust spacing between legend items
                    }}/>
                {question.labels.map((label, index) => (
                  <Bar
                    key={label}
                    dataKey={label}
                    fill={colors[index % colors.length]}
                  >
                     <LabelList
                      dataKey={label}
                      position="insideRight"
                      style={{
                        fill: "white",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                      formatter={(value) => `${value}%`} // Format as needed
                    />
                  </Bar>
                ))}
              </BarChart>
            </ResponsiveContainer>
            <button
              onClick={toggleZoom}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                border: "none",
                cursor: "pointer",
                backgroundColor: "#007A7A",
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
                fontSize: "18px",
              }}
            >
              <FontAwesomeIcon icon={faExpandArrowsAlt} color={"#fff"} />
            </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}






