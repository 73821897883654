import { useMutation } from "@tanstack/react-query";
import { useLoader } from "../../../../components/common/WEBLoaderProvider";
import surveyAnalysisApi from "../surveyAnalysisApi";
import { handleApiError } from "../../../../components/common/handleApiError";

export const useMutateGetSurveyAnalysis = () => {
    const { hideLoader} = useLoader()
  return useMutation(
    ({ groupId, schoolId, surveyId, filter}) => surveyAnalysisApi.GetSurveyAnalysis(groupId, schoolId, surveyId, filter),
    {
      onError: (error) => {
        hideLoader();
        handleApiError(error, 'Failed to load selected survey.');
      },
  
      retry:false
    }
  );
};
