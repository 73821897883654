import React from "react";
import { Container, Grid, Typography, Alert } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, LabelList } from "recharts";

export const CommentsWithSentimentChart = ({ sentimentData }) => {
    console.log(sentimentData);
    const sentimentColors = {
      Positive: "#007A7A", 
      Neutral: "#53B5E0",  
      Negative: "#E84F6B",
    };
  
    return (
      <Container maxWidth="lg"  
      style={{
        position: 'relative',

       
        border: '0px solid #ccc',
        borderRadius: '0px',
        paddingTop: '50px',
        backgroundColor: '#fff',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      }}
  
    >
        <Grid container mt={2} spacing={2}>
          {sentimentData?.comments?.length ===0 ? (
            <Alert severity="info" sx={{ my: 2, mx: 2, width: "100%" }}>
              No comments found.
            </Alert>
          ) : (
            <>
              {/* Sentiment Analysis Chart */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h5" align="center" gutterBottom>
                  Sentiment Analysis
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={sentimentData?.commentsSentiment}
                    layout="horizontal"
                    //margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    barSize={40}
                    barCategoryGap={10}
                    barGap={10}
                  >
                    <CartesianGrid strokeDasharray="3 3"   horizontal={false} vertical={false} />
                    <XAxis dataKey="sentiment" />
                    <YAxis />
                    <Tooltip />
                  
                    <Bar dataKey="count">
                    {sentimentData?.commentsSentiment.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={sentimentColors[entry.sentiment]}
                      />
                      
                    ))}
                     <LabelList dataKey="count" position="top"
                      fill="#000"
                      fontSize={16} fontStyle="bold" />
                  </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
  
              {/* Comments Section */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h5" align="center" gutterBottom>
                  Comments
                </Typography>
                <div className="body-container" style={{ padding: "0" }}>
                  <Grid container spacing={2}>
                    {sentimentData?.comments?.map((comment, index) => (
                      <Grid item lg={12} md={12} sm={12} xs={12} key={index} style={{ paddingTop:"0px"}}>
                        <div className="comments" style={{ borderLeft:" 4px solid #FF5939"}}>
                          <Typography
                            variant="body2"
                            
                          >
                            <div className="comment"> {comment}</div>
                           
                          </Typography>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    );
  }
  
