import React, {useEffect, useRef, useState} from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  LabelList,
  Cell,
  Legend,
} from 'recharts'
import {styled} from '@mui/material/styles'
import TooltipMui, {tooltipClasses} from '@mui/material/Tooltip'

// Styled Tooltip
const QuestionTooltip = styled(({className, ...props}) => (
  <TooltipMui placement="top" arrow {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    color: '#ffffff',
    maxWidth: 320,
    fontSize: 16,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgba(0,0,0,0.9)',
  },
}));

export const HeatmapChart = ({heatmapData}) => {
  const chartContainerRef = useRef(null)
  const [headerWidth, setHeaderWidth] = useState(1300)
  const [totalHeight, setTotalHeight] = useState(600);

  // Extract labels based on available scores
  const extractLabels = () => {
    const labels = []
    if (heatmapData.some(item => item.staffScore)) labels.push('Staff')
    if (heatmapData.some(item => item.parentScore)) labels.push('Parent')
    if (heatmapData.some(item => item.studentScore)) labels.push('Student')
    if (heatmapData.some(item => item.overAllScore)) labels.push('Overall')
    return labels
  }

  const yLabels = extractLabels()

  // Extract unique questions
  const questions = [...new Set(heatmapData.map(item => item.question))].sort(
    (a, b) => {
      const aNum = parseInt(a.replace('Q', ''), 10)
      const bNum = parseInt(b.replace('Q', ''), 10)
      return aNum - bNum
    }
  )

  const barHeight = 30
  const padding = 20
  const gap = 1.8

  // Map data for each question
  const questionData = questions.map(question => {
    const isFilterBased = heatmapData.some(item => item.filterValue)
   
    if (isFilterBased) {
      // Group data by filter values for filter-based data
      const groupedData = []
      const uniqueFilters = [
        ...new Set(
          heatmapData
            .filter(item => item.question === question)
            .map(item => item.filterValue)
        ),
      ]

      uniqueFilters.forEach(filter => {
        const dataItem = heatmapData.find(
          item => item.question === question && item.filterValue === filter
        )

        groupedData.push({
          label: filter,
          Staff: dataItem?.staffScore || 0,
          Parent: dataItem?.parentScore || 0,
          Student: dataItem?.studentScore || 0,
          Overall: dataItem?.overAllScore || 0,
        })
      })

      return groupedData
    }
    else{
      const generalData = []
      const dataItem = heatmapData.find(item => item.question === question)
  
      yLabels.forEach(label => {
        if (label === 'Staff' && (dataItem?.staffScore !== undefined || dataItem?.staffScore !== null || dataItem?.staffScore !== 0)) {
          generalData.push({
            label,
            Staff: dataItem?.staffScore || undefined,
          })
        } else if (label === 'Parent' && (dataItem?.parentScore !== undefined || dataItem?.parentScore !== null || dataItem?.parentScore !== 0)) {
          generalData.push({
            label,
            Parent: dataItem?.parentScore || undefined,
          })
        } else if (label === 'Student' && (dataItem?.studentScore !== undefined || dataItem?.studentScore !== null || dataItem?.studentScore !== 0)) {
          generalData.push({
            label,
            Student: dataItem?.studentScore || undefined,
          })
        } else if (label === 'Overall' && (dataItem?.overAllScore !== undefined || dataItem?.overAllScore !== null || dataItem?.overAllScore !== 0)) {
          generalData.push({
            label,
            Overall: dataItem?.overAllScore || undefined,
          })
        }
      })
  
      return generalData
    }
  })
 

  // Update header width dynamically
  useEffect(() => {
    const calculateWidth = () => {
      const n = questions.length
      return n === 1
        ? 1300
        : n === 2
          ? 800 + (n - 1) * 800
          : 500 + (n - 1) * 500
    }
    setHeaderWidth(calculateWidth())

    const calculateTotalHeight = () => {
      const isFilterBased = heatmapData.some(item => item.filterValue);
      const totalGroups = isFilterBased
        ? new Set(heatmapData.map(item => item.filterValue)).size
        : yLabels.length;
      return totalGroups * barHeight * gap + padding;
    };
    setTotalHeight(calculateTotalHeight());
  }, [questions])

  const calculateDomain = data => {
    const allValues = data.flatMap(qData => qData.map(entry => entry.value))
    const maxValue = Math.max(...allValues)
    return [0, maxValue > 4 ? maxValue : 5] // Set minimum domain to 5 for visibility.
  }

  const xDomain = calculateDomain(questionData)
  const tickIndices = new Map()
  function renderWrappedText(x, y, text) {
    const words = text.split(' ')

    if (words.length <= 2) {
      return (
        <tspan x={x} y={y}>
          {text}
        </tspan>
      )
    }

    const lines = []
    let currentLine = words[0] + ' ' + words[1]
    let startIndex = 2

    for (let i = startIndex; i < words.length; i++) {
      if (currentLine.length + words[i].length <= 10) {
        currentLine += ` ${words[i]}`
      } else {
        lines.push(currentLine)
        currentLine = words[i]
      }
    }
    lines.push(currentLine)

    return lines.map((line, i) => (
      <tspan x={x} y={y + i * 16} key={i}>
        {line}
      </tspan>
    ))
  }
  const renderCustomYAxisTick = (tickProps, counts, barHeight) => {
    const {x, y, payload} = tickProps
    const value = payload.value

    const currentIndex = tickIndices.has(value) ? tickIndices.get(value) + 1 : 0
    tickIndices.set(value, currentIndex)
    if (currentIndex === 0) {
      const adjustedY = y + 1
      return (
        <text
          x={x - 10}
          y={adjustedY}
          fontSize={10}
          fontWeight={'bold'}
          textAnchor="end"
          fill="black"
        >
          {renderWrappedText(x - 10, adjustedY, value)}
        </text>
      )
    }
  }
  console.log(questionData)
  return (
    <div>
      <div
        style={{
          position: 'relative',

          overflowX: 'auto',
          overflowY: 'auto', // Enable vertical scroll
          maxHeight: '400px', // Set a maximum height for vertical scrolling
          border: '0px solid #ccc',
          borderRadius: '0px',
          paddingTop: '50px',
          backgroundColor: '#fff',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        }}
        ref={chartContainerRef}
      >
        {/* Sticky Header */}
        <div
          style={{
            position: 'sticky',
            top: 0,
            display: 'flex',
            zIndex: 10,
            padding: '0px 0',
            backgroundColor: '#fff',
            justifyContent: 'left',
            width: `${headerWidth}px`,
          }}
        >
           <div style={{display: 'flex', width:'135px'}}></div>
          {questions.map((question, index) => (
            <div
              key={question}
              style={{
                width: `${
                  questionData?.length === 1
                    ? 1050
                    : questionData?.length === 2
                      ? index === 0
                        ? 700
                        : 620
                      : index === 0
                        ? 335
                        : 300
                }px`,
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '16px',

              }}
            >
              <QuestionTooltip
                title={`${
                  heatmapData.find(item => item.question === question)
                    ?.questionText
                }`}
                placement="top"
                arrow
              >
                {question}
              </QuestionTooltip>
            </div>
          ))}
        </div>

        {/* Heatmap Charts */}
        <div style={{display: 'flex'}}>
       
          {questionData.map((data, index) => (
            <div key={questions[index]} style={{textAlign: 'center'}}>
              <BarChart
                width={
                  questionData?.length === 1
                    ? 1050
                    : questionData?.length === 2
                      ? index === 0
                        ? 700
                        : 620
                      : index === 0
                        ? 435+69
                        : 300
                }
                barSize={30}
                barGap={10}

                height={totalHeight}
                data={data}
                layout="vertical"
              >
                <CartesianGrid horizontal={false} vertical={false} />
                <XAxis type="number" domain={xDomain} hide={true} />
                <YAxis
                  type="category"
                  dataKey="label"
                  tickLine={false}
                  axisLine={false}
                  width={135}
                  hide={index !== 0}
                  tick={props =>
                    renderCustomYAxisTick(props, questionData?.length, 30)
                  }
                />
                <Tooltip
                  formatter={value => value?.toFixed(2)}
                  cursor={{fill: 'rgba(255, 255, 255, 0.5)'}}
                />
                {index === 0 && (
                  <Legend
                    align="left"
                    verticalAlign="top"
                    layout="vertical"
                    
                    wrapperStyle={{
                      paddingLeft: '10px',
                      position: 'absolute',
                      left: 0,
                     // top: '50%',
                      //transform: 'translateY(-50%)', // Center vertically
                      lineHeight: '24px', // Adjust spacing between legend items
                    }}
                  />
                )}
                {yLabels.map((label, idx) => (
                  <Bar
                    key={idx}
                    dataKey={label}
                    background={{fill: '#eee'}}
                    fill={
                      label === 'Staff'
                        ? '#ff6699'
                        : label === 'Parent'
                          ? '#ffcc33'
                          : label === 'Student'
                            ? '#4db8ff'
                            : '#007A7A'
                    }
                  >
                    <LabelList
                      dataKey={
                        label === 'Staff'
                          ? 'Staff'
                          : label === 'Parent'
                            ? 'Parent'
                            : label === 'Student'
                              ? 'Student'
                              : 'Overall'
                      }
                      position="insideRight"
                      style={{
                        fill: 'white',
                        fontSize: 12,
                        fontWeight: 'bold',
                      }}
                    />
                  </Bar>
                ))}
              </BarChart>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
