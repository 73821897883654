import {Grid, Link, MenuItem, Container, Stack, Alert} from '@mui/material'
import React, {useEffect, useState} from 'react'
import surveysApi from '../../../services/api/surveys/surveysApi'
import {AUDIENCE, CUSTOMER_TYPE, QUESTION_TYPE} from '../../../utils/constants'
import WbAlert from '../../../components/common/WbAlert'
import questionsApi from '../../../services/api/questionLibrary/questionsApi'
import WbTextField from '../../../components/common/WbTextField'
import commentsApi from '../../../services/api/comments/commentsApi'
import WbModal from '../../../components/common/WbModal'
import ShowNameConcern from './ShowNameConcern'
import ReplyComment from './ReplyComment'
import useCustomerData from '../../../hooks/useCustomerData'
import WbLoader from '../../../components/common/WbLoader'
import Header from '../../../components/layouts/header/Header'
import { useLoader } from '../../../components/common/WEBLoaderProvider'

export default function Comments() {
  const {customerType, id} = useCustomerData()
  const [loading, setLoading] = useState(false)
  const [surveys, setSurveys] = useState([])
  const [questions, setQuestions] = useState([])
  const [schools, setSchools] = useState([])
  const [comments, setComments] = useState([])
  const [audiences, setAudiences] = useState([])

  const [selectedSurvey, setSelectedSurvey] = useState(null)
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [selectedSchool, setSelectedSchool] = useState(null)
  const [selectedAudience, setSelectedAudience] = useState(null)
  const [showConcerned, setShowConcerned] = useState(false)
  const [selectedParticipant, setSelectedParticipant] = useState(-1)
  const {showLoader, hideLoader} = useLoader()

  const loadComments = () => {
    if (selectedSurvey !== null) {
      setLoading(true)
      showLoader();
      commentsApi
        .get(
          selectedSurvey?.id,
          selectedQuestion?.id ? selectedQuestion?.id : null,
          selectedSchool?.id ? selectedSchool?.id : null
        )
        .then(data => {
          setComments(data)
        })
        .finally(() => {hideLoader(); setLoading(false)})
    }
  }

  const getDate = String => {
    var d = new Date(String).toDateString()
    return d
  }

  const loadAudiences = () => {
    let newAudiences = [{id: null, name: 'All'}]
    if (selectedSurvey !== null) {
      if (
        selectedSurvey.audience === 1 ||
        selectedSurvey.audience === 3 ||
        selectedSurvey.audience === 5 ||
        selectedSurvey.audience === 7
      ) {
        newAudiences.push({id: 1, name: 'Staff'})
      }

      if (
        selectedSurvey.audience === 2 ||
        selectedSurvey.audience === 3 ||
        selectedSurvey.audience === 5 ||
        selectedSurvey.audience === 7
      ) {
        newAudiences.push({id: 2, name: 'Parents'})
      }

      if (
        selectedSurvey.audience === 4 ||
        selectedSurvey.audience === 5 ||
        selectedSurvey.audience === 6 ||
        selectedSurvey.audience === 7
      ) {
        newAudiences.push({id: 4, name: 'Students'})
      }
      setSelectedAudience(newAudiences[0])
    }
    setAudiences(newAudiences)
  }
  useEffect(() => {
    if (selectedSurvey) {
      showLoader();
      questionsApi
        .getListByIds(selectedSurvey?.surveyQuestions?.map(s => s.questionId))
        .then(questions => {
          //debugger
          setQuestions(
            questions.filter(q => q.questionType === QUESTION_TYPE.COMMENTS)
          )
        }).finally(() => hideLoader())
    }
  }, [selectedSurvey])

  useEffect(() => {
    loadComments()
    loadAudiences()
  }, [selectedSurvey, selectedQuestion, selectedSchool])

  useEffect(() => {
    showLoader();
    surveysApi
      .getSurveyForResults(
        customerType === CUSTOMER_TYPE.School ? id : 0,
        customerType === CUSTOMER_TYPE.MAT ? id : 0
      )
      .then(data => {
        setSurveys(data)
        // if (data.length > 0) {
        //   setSelectedSurvey(data[0])
        // }
        if (customerType === CUSTOMER_TYPE.MAT) {
          setSchools(data[0].surveySchools)
        } else {
          let ss = [{id: id, name: ''}]
          setSchools(ss)
          setSelectedSchool(ss[0])
        }
        questionsApi
          .getListByIds(data[0].surveyQuestions?.map(s => s.questionId))
          .then(questions => {
            setQuestions(questions)
            // if (questions.length > 0) {
            //   setSelectedQuestion(questions[0])
            // }
          })
      })
      .catch(e => WbAlert({message: e.message, type: 'error'}))
      .finally(() => {hideLoader(); setLoading(false)})
  }, [])
  const getqText = q => {
    if (selectedAudience?.id === null) {
      return q.staffText
    } else if (selectedAudience?.id === 1) {
      return q.staffText
    } else if (selectedAudience?.id === 2) {
      return q.parentText
    } else if (selectedAudience?.id === 4) {
      return q.studentText
    }
  }
  return (
    <WbLoader>
        <Header />
    <Container maxWidth="lg">
      <Grid container mt={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <h1>Comments</h1>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>          
          <Stack direction="row" spacing={1}>
            <WbTextField
              select
              label="Select Survey"
              value={selectedSurvey}
              onChange={e => setSelectedSurvey(e.target.value)}
            >
              {surveys?.map(s => (
                <MenuItem value={s}>{s.name}</MenuItem>
              ))}
            </WbTextField>
            <WbTextField
              select
              label="Select Question"
              value={selectedQuestion}
              onChange={e => {
                setSelectedQuestion(e.target.value)
              }}
            >
              {questions?.map(s => (
                <MenuItem value={s}>{getqText(s)}</MenuItem>
              ))}
            </WbTextField>
          
            {customerType === CUSTOMER_TYPE.MAT && (
              <WbTextField
                select
                label="Select School"
                value={selectedSchool}
                onChange={e => setSelectedSchool(e.target.value)}
              >
                {schools?.map(s => (
                  <MenuItem value={s}>{s.schoolName}</MenuItem>
                ))}
              </WbTextField>
            )}
            <WbTextField
              select
              label="Audiences"
              value={selectedAudience}
              onChange={e => {
                setSelectedAudience(e.target.value)
              }}
            >
              {audiences?.map(s => (
                <MenuItem value={s}>{s.name}</MenuItem>
              ))}
            </WbTextField>
          </Stack>         
        </Grid>
        {questions.length > 0 ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className="body-container" style={{padding: '0'}}>
              <Grid container>
                {comments.filter(x =>
                  selectedAudience?.id
                    ? x.audience === selectedAudience?.id
                    : true
                ).length === 0
                  ? <Alert severity="info" sx={{my:2, mx:2, width: '100%'}}>No comments found</Alert>
                  : comments
                      .filter(x =>
                        selectedAudience?.id
                          ? x.audience === selectedAudience?.id
                          : true
                      )
                      .map(c => (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <div className="comments">
                            <div className="comment-date">
                              {getDate(c.dateTime)}
                            </div>
                            <div className="comment">{c.comment}</div>
                            {c.audience === AUDIENCE.STUDENT ? (
                              <Link
                                className="comment-link"
                                onClick={e => {
                                  setShowConcerned(true)
                                  setSelectedParticipant(c.participantId)
                                }}
                              >
                                Concerned about this comment?
                              </Link>
                            ) : (
                              <ReplyComment commentId={c.id} isAdmin={true} />
                            )}
                          </div>
                        </Grid>
                      ))}
              </Grid>
            </div>
          </Grid>
        ) : (
          <Grid item lg={12} md={12} sm={12} xs={12}>
           
          <div className="body-container" style={{padding: '0'}}>
          <Alert severity="info" sx={{my:2, mx:2, width: '97%'}}>No comments found</Alert>
          </div>
      
          </Grid>
        )}
        {showConcerned && (
          <WbModal
            title="Concerned about this comment?"
            content={
              <ShowNameConcern
                showHideConcerned={setShowConcerned}
                participantId={selectedParticipant}
                isAnonymous={selectedSurvey.anonimous}
              />
            }
            onClose={() => setShowConcerned(false)}
          />
        )}
      </Grid>
    </Container>
    </WbLoader>
  )
}
