import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './components/assets/styles/App.scss'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import { Provider, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import {store} from './redux/store';
import userManager, { loadUserFromStorage } from './services/api/user/userApi'
import ProtectedRoute from './routes/protectedRoutes';
import paths from './routes/paths';
import SigninOidc from './views/Account/SignIn';
import AuthProvider from './utils/authProvidor';
import Login from './views/Account/Login.jsx';
import EDUHome from './views/EDUHome/EDUHome.jsx';
import EDUDashboard from './views/EDUDashboard/EDUDashboard.jsx';
import EDUEmbeddableDashboardOne from './views/EDUEmbeddableDashboardOne/EDUEmbeddableDashboardOne.jsx'
import EDUEmbeddableDashboardTwo from './views/EDUEmbeddableDashboardTwo/EDUEmbeddableDashboardTwo.jsx'
import EDUEmbeddableDashboardThree from './views/EDUEmbeddableDashboardThree/EDUEmbeddableDashboardThree.jsx'
import EDUEmbeddableDashboardFour from './views/EDUEmbeddableDashboardFour/EDUEmbeddableDashboardFour.jsx'
import EDUQuestionsDashboard from './views/EDUQuestionsDashboard/EDUQuestionsDashboard.jsx';
import EDUDashboard2 from './views/EDUDashboard2/EDUDashboard2.jsx';
import EDUDashboard3 from './views/EDUDashboard3/EDUDashboard3.jsx';
import ParticipantReply from './views/ResultsPanel/Comments/ParticipantReply.jsx';
import Redirect from './views/SuperAdmin/Redirect.jsx';
import EDUDashboard5 from './views/EDUDashboard5/EDUDashboard5.jsx';
import EDUDashboard4 from './views/EDUDashboard4/EDUDashboard4.jsx';
import EDUDashboard6 from './views/EDUDashboard6/EDUDashboard6.jsx';
import SurveyAnalysis from './views/SurveyAnalysis/SurveyAnalysis.jsx';
import SurveyView from './views/SurveyView';
import Comments from './views/ResultsPanel/Comments/index.jsx';

const theme = createTheme({
  typography: {
    fontFamily: ['Inter, sans-serif'].join(','),
  },
});

function App() {

  useEffect(() => {
    const skipStorage = ["/login", "/login-redirect", "/"];
    if (!skipStorage.includes(window.location.pathname)) {
      var url = window.location.pathname + window.location.search;
      localStorage.setItem('RedirectUrl', url);
    }

    loadUserFromStorage(store)
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AuthProvider userManager={userManager} store={store}>
          <Router>
            <Routes>
            <Route path='/' element={<Login />} />
              <Route path={paths.login} element={<Login />} />
              <Route path={paths.loginRedirect} element={<SigninOidc />} />
              <Route element={<ProtectedRoute/>}>
                <Route path={paths.EDUHome} element={<EDUHome />} />
                <Route path={paths.EDUDashboard} element={<EDUDashboard />} />
                <Route path={paths.EDUEmbeddableDashboardOne} element={<EDUEmbeddableDashboardOne />} />
                <Route path={paths.EDUEmbeddableDashboardTwo} element={<EDUEmbeddableDashboardTwo />} />
                <Route path={paths.EDUEmbeddableDashboardThree} element={<EDUEmbeddableDashboardThree />} />
                <Route path={paths.EDUEmbeddableDashboardFour} element={<EDUEmbeddableDashboardFour />} />
                <Route path={paths.EDUQuestionsDashboard} element={<EDUQuestionsDashboard />} />
                <Route path={paths.EDUDashboard2} element={<EDUDashboard2 />} />
                <Route path={paths.EDUDashboard3} element={<EDUDashboard3 />} />
                <Route path={paths.SurveyAnalysis} element={<SurveyAnalysis />} />
                
                <Route path={paths.EDUDashboard4} element={<EDUDashboard4 />} />
                <Route path={paths.EDUDashboard5} element={<EDUDashboard5 />} />
                <Route path={paths.EDUDashboard6} element={<EDUDashboard6 />} />
                <Route path={paths.surveyView} element={<SurveyView />} />
                <Route path={paths.participantsReply} element={<ParticipantReply />} />
                <Route path={paths.superAdminRedirect} element={<Redirect />} />
                <Route path={paths.comments} element={<Comments />} />
              </Route>
            </Routes>
          </Router>
        </AuthProvider>
      </Provider>
    </ThemeProvider>
  )
}
export default App
