import React, {useState} from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
  LabelList,
} from 'recharts'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExpandArrowsAlt} from '@fortawesome/free-solid-svg-icons'

export const ColumnChartRecharts = ({surveyData}) => {
  const [isZoomed, setIsZoomed] = useState(false)

  const toggleZoom = () => {
    setIsZoomed(!isZoomed)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        justifyContent: 'space-between',
      }}
    >
      {surveyData?.map((item, index) => (
        <div
          key={index}
          style={{
            width: isZoomed ? '100%' : '49.5%',
            backgroundColor: '#fff',
            borderRadius: '0.0rem',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            marginBottom: '10px',
            position: 'relative',
            transition: 'width 0.3s',
          }}
        >
          <h3 style={{textAlign: 'center'}}>{item.questionHeader}</h3>
          <div style={{textAlign: 'center'}}>{item.questionText}</div>
          <div
            style={{paddingTop: '14px', height: isZoomed ? '400px' : '300px'}}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={item.labels.map((label, labelIndex) => ({
                  label,
                  ...item.datasets.reduce((acc, dataset) => {
                    acc[dataset.label] = dataset.data[labelIndex]
                    return acc
                  }, {}),
                }))}
                barCategoryGap={5}
                barGap={5}
                barSize={40}
                //margin={{top: 20, right: 30, left: 20, bottom: 20}}
              >
                <XAxis
                  dataKey="label"
                  tickLine={false}
                  axisLine={{stroke: '#ccc'}}
                  interval={0}
                  style={{fontSize: '12px'}}
                />
                <YAxis
                  tickFormatter={value => `${value}%`}
                  allowDecimals={false}
                  axisLine={{stroke: '#ccc'}}
                />
                <Tooltip formatter={value => `${value}%`} />
                <Legend wrapperStyle={{fontSize: '12px'}} />
                {item.datasets.map((dataset, datasetIndex) => (
                  <Bar
                    key={datasetIndex}
                    dataKey={dataset.label}
                    fill={dataset.backgroundColor}
                  >
                    {item.labels.map((_, labelIndex) => (
                      <Cell key={`cell-${labelIndex}`} />
                    ))}
                    <LabelList
                      dataKey={dataset.label} // Use dataset.label as the dataKey
                      position="top"
                      fill="#000"
                      fontSize={12}
                      formatter={value => `${value}%`} // Add percentage sign
                    />
                  </Bar>
                ))}
              </BarChart>
            </ResponsiveContainer>
          </div>
          <button
            onClick={toggleZoom}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: 'none',
              cursor: 'pointer',
              backgroundColor: '#007A7A',
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
              fontSize: '18px',
            }}
          >
            <FontAwesomeIcon icon={faExpandArrowsAlt} color={'#fff'} />
          </button>
        </div>
      ))}
    </div>
  )
}
