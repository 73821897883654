import React from "react";
import { PieChart, Pie, Cell, Label } from "recharts";

export const GaugeChart = ({ participationRate, width=300, height=200 }) => {
  const value = parseFloat(participationRate.replace("%", "")) || 0;

  // Data for the Gauge Chart
  const data = [
    { name: "Participation", value },
    { name: "Remaining", value: 100 - value },
  ];

  const colors = ["#4db8ff", "#e0e0e0"];

  return (
    <div style={{ width: `${width}px`, margin: "auto", textAlign: "center" }}>
      <PieChart width={width} height={height}>
        <Pie
          data={data}
          startAngle={180}
          endAngle={0}
          innerRadius={80}
          outerRadius={100}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index]} />
          ))}
          <Label
            value={`${value}%`}
            position="center"
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              fill: "#4db8ff",
            }}
          />
        </Pie>
      </PieChart>
      <div style={{ fontSize: "16px", marginTop: "-20px", color:"#000" }}>
        Participation Rate
      </div>
    </div>
  );
}
