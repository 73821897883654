import { UserManager, WebStorageStateStore } from 'oidc-client';
import { storeUserError, storeUser } from '../../../actions/authAction';
import {Config} from '../../../utils/Config';

const authApiUrl = 'https://api.identity.welbee.co.uk';

const config = {
  authority: authApiUrl,
  redirect_uri: Config.App_Url + "/login-redirect",
  response_type: "code",
  scope: "openid profile email",
  post_logout_redirect_uri: Config.App_Url + "/login",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
  automaticSilentRenew: false,
  sessionMonitor: false
};

if (Config.App_Url === 'http://localhost:3000'){
  config.client_id = 'EduIntelligenceAppLocal';
} else if (Config.App_Url === 'https://s.ei.welbee.co.uk') {
  config.client_id = 'EduIntelligenceAppStaging';
} else {
  config.client_id = 'EduIntelligenceApp';
}

const userManager = new UserManager(config)

export async function loadUserFromStorage(store) {
  try {
    let user = await userManager.getUser()
    if (!user) { return store.dispatch(storeUserError()) }
    store.dispatch(storeUser(user))
  } catch (e) {
    console.error(`User not found: ${e}`)
    store.dispatch(storeUserError())
  }
}

export function signinRedirect() {
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function signoutRedirect() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirect();
}

export function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirectCallback();
}

export default userManager;