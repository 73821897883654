import React, { useEffect, useState } from 'react';
import { Container, Grid, Stack, Chip, MenuItem } from '@mui/material';
import Header from '../../components/layouts/header/Header';
import WbTextField from '../../components/common/WbTextField';
import useCustomerData from '../../hooks/useCustomerData';
import SurveyCharts3 from '../DemoChartDashboard3';
import WbFloatButtonV3 from '../../components/common/WbFloatButtonV3';
import { useQuery } from '@tanstack/react-query';
import surveysApi from '../../services/api/surveys/surveysApi';
import WbFloatButton from '../../components/common/WbFloatButton';
import { useNavigate } from 'react-router-dom';
import HeatmapTable from '../Heatmap4';

const averageScoresData = [
  { question: 'Q1', studentScore: 2.4, parentScore: 3.5, staffScore: 3.8 },
  { question: 'Q2', studentScore: 3.8, parentScore: 3.6, staffScore: 3.9 },
  { question: 'Q3', studentScore: 3.9, parentScore: 4.0, staffScore: 4.2 },
  { question: 'Q4', studentScore: 4.0, parentScore: 4.1, staffScore: 4.3 },
  { question: 'Q5', studentScore: 4.0, parentScore: 4.1, staffScore: 4.0 },
  { question: 'Q6', studentScore: 3.2, parentScore: 3.7, staffScore: 3.8 },
  { question: 'Q7', studentScore: 3.9, parentScore: 3.6, staffScore: 3.9 },
  { question: 'Q8', studentScore: 4.7, parentScore: 4.9, staffScore: 4.8 },
];

export const surveyOptions = [
  { id: 55, name: 'Riverdale Academy Trust Survey' },
  { id: 150, name: 'Greenwood Multi-Academy Trust KINDL Wellbeing Survey' },
  { id: 90, name: 'Riverdale Academy Trust E-Safety Survey' },
  { id: 110, name: 'Riverdale Academy Trust Parent Feedback Survey' }
];
export default function EDUDashboard3() {
  const navigate = useNavigate()
  const customer ={
    id: 2007,
    customerType: 1
  }; //useCustomerData();
  const [selectedSurvey, setSelectedSurvey] = useState(55);

  const [currentSurveyDetails, setCurrentSurveyDetails] = useState(surveyOptions[0].details);

  useEffect(() => {
    // Update current survey details whenever selectedSurvey changes
    const selectedDetails = surveyOptions.find(option => option.id === selectedSurvey)?.details;
    if (selectedDetails) {
      setCurrentSurveyDetails(selectedDetails);
    }
  }, [selectedSurvey]);

  // Fetch all survey options using react-query
  // const { data: surveyOptions1 = [], isLoading: isLoadingSurveys } = useQuery(
  //   ['surveys', customer.id, customer.customerType],
  //   () => surveysApi.getAll(customer.id, customer.customerType, 50),
  //   {
  //     onSuccess: (res) => {
  //       if (res && res.length > 0) {
  //         setSelectedSurvey(res[0].id);
  //       }
  //     },
  //   }
  // );

  // // Fetch detailed survey data for the selected survey
  // const { data: survey = {}, isLoading: isLoadingSurveyDetails } = useQuery(
  //   ['surveyDetails', selectedSurvey],
  //   () => (selectedSurvey ? surveysApi.getBySurveyId(selectedSurvey) : Promise.resolve(null)),
  //   {
  //     enabled: !!selectedSurvey, // Only run this query if a survey is selected
  //   }
  // );

  const handleSurveyChange = (e) => {
    const surveyId = e.target.value;
    setSelectedSurvey(surveyId);
    if (surveyId === 55) {
      navigate('/EDUDashboard3');
    } else if (surveyId === 150) {
      navigate('/EDUDashboard4');
    } else if (surveyId === 90) {
      navigate('/EDUDashboard5');
    } else if (surveyId === 110) {
      navigate('/EDUDashboard6');
    }
  };
  const getDateString = (date, format) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const getPaddedComp = (comp) => (parseInt(comp) < 10 ? '0' + comp : comp);
    let formattedDate = format;
    const o = {
      'y+': date.getFullYear(), // year
      'M+': months[date.getMonth()], // month
      'd+': getPaddedComp(date.getDate()), // day
      'h+': getPaddedComp(date.getHours() > 12 ? date.getHours() % 12 : date.getHours()), // hour (12-hour format)
      'H+': getPaddedComp(date.getHours()), // hour (24-hour format)
      'm+': getPaddedComp(date.getMinutes()), // minute
      's+': getPaddedComp(date.getSeconds()), // second
      'S+': getPaddedComp(date.getMilliseconds()), // millisecond
      'b+': date.getHours() >= 12 ? 'PM' : 'AM', // AM/PM
    };

    for (let k in o) {
      if (new RegExp('(' + k + ')').test(format)) {
        formattedDate = formattedDate.replace(RegExp.$1, o[k]);
      }
    }
    return formattedDate;
  };

  const getAudienceChip = (audience) => {
    switch (audience) {
      case 1:
        return <Chip className="audience-chip" label={'Staff'} size="small" />;
      case 2:
        return <Chip className="audience-chip" label={'Parents'} size="small" />;
      case 3:
        return (
          <>
            <Chip className="audience-chip" label={'Staff'} size="small" />
            <Chip className="audience-chip" label={'Parents'} size="small" />
          </>
        );
      case 4:
        return <Chip className="audience-chip" label={'Students'} size="small" />;
      case 5:
        return (
          <>
            <Chip className="audience-chip" label={'Students'} size="small" />
            <Chip className="audience-chip" label={'Staff'} size="small" />
          </>
        );
      case 6:
        return (
          <>
            <Chip className="audience-chip" label={'Students'} size="small" />
            <Chip className="audience-chip" label={'Parents'} size="small" />
          </>
        );
      default:
        return (
          <>
            <Chip className="audience-chip" label={'Staff'} size="small" />
            <Chip className="audience-chip" label={'Students'} size="small" />
            <Chip className="audience-chip" label={'Parents'} size="small" />
          </>
        );
    }
  };

  return (
    <>
      <Header />
      <div className="main-container insights-container">
        <Container maxWidth="lg">
          <Grid container mt={3}>
            <Grid item lg={7} md={7} sm={6} xs={12}>
              <h1>Latest Survey</h1>
              <Stack direction="column" mt={4}>
    <h2>{'Riverdale Academy Trust Survey'}</h2>
    <Stack direction="row" alignItems="center" my={0}>
      Audience:
      <Stack direction="row" style={{marginBottom:'2px' , marginTop:'2px' }} className="launch-date" spacing={0.5} ml={0.5}>
        {/* Replace with dynamic audiences if needed */}
        {['Students', 'Parents','Staff'].map((audience, index) => (
          <Chip key={index} className="audience-chip" label={audience} size="small" />
        ))}
      </Stack>
    </Stack>
    <div className="launch-date">
      Launch date/time: <span>{'01-10-2024'}</span>
    </div>
    <div className="launch-date">
      End date/time: <span>{'31-10-2024'}</span>
    </div>
    <div className="launch-date">
      Total submissions: <span>{'700'}</span>
    </div>
    <div className="launch-date">
      Participation rate: <span>{'70%'}</span>
    </div>
    <div className="launch-date">
    Analysis level: <span>{'Trust'}</span>
  </div>
 
  </Stack>
            </Grid>

            <Grid item lg={5} md={5} sm={6} xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <WbTextField id="SurveyDropDown" value={selectedSurvey} label="Select Survey" onChange={handleSurveyChange}  defaultValue={selectedSurvey} select>
                    {surveyOptions.map((result) => (
                      <MenuItem key={result.id} value={result.id}>
                        {result.name}
                      </MenuItem>
                    ))}
                  </WbTextField>
                </Grid>
                {customer.customerType !== 2 ? (
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <WbTextField label="Select School" select>
                      <MenuItem value={1}>School One</MenuItem>
                      <MenuItem value={2}>School Two</MenuItem>
                      <MenuItem value={3}>School Three</MenuItem>
                    </WbTextField>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <HeatmapTable scoreData={averageScoresData} />
            </Grid>
         
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <SurveyCharts3 />
            </Grid>
          </Grid>
        </Container>
      </div>

      <WbFloatButton surveyInfo={{ surveyId: 55, customerId: customer.id, customerType:customer.customerType,datasetval:"dev_global_dataset" }} />
      
    </>
  );
}
