import zIndex from '@mui/material/styles/zIndex';
import { min } from 'lodash';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const MultiSelectDropdown = ({ filterOptions, placeholder, onChange, isMulti =true , defaultValue = null}) => {
    const [selectedOptions, setSelectedOptions] = useState(defaultValue || []); 

    const options = filterOptions?.map((filter) => ({
        value: filter.id || filter.value || filter, // Use `id` if available, else fallback
        label: filter.name || filter.label || filter, // Use `name` if available, else fallback
      }));
    
  
    useEffect(() => {
      // Sync with defaultValue if it changes
      if (defaultValue) {
        setSelectedOptions(defaultValue);
      }
    }, [defaultValue]);
  
    const handleChange = (selected) => {
      setSelectedOptions(selected);
      if (onChange) {
        onChange(selected);
      }
    };
  
    const customStyles = {
        zIndex: 1050,
      control: (base, state) => ({
        ...base,
        borderRadius: 0,
        overflow: "hidden",
        backgroundColor: "#ffffff",
        minHeight: "48px",
        
        border: "1px solid #e2e2e2",
        borderColor: state.isFocused ? "#53B5E0" : "#e2e2e2",
        fontSize: "14px",
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
        transition: "border-color 0.3s, background-color 0.3s",
        "&:hover": {
          backgroundColor: "#ffffff",
          borderColor: "#e2e2e2",
        },
      }),
      option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? "#E84F6B" : "#fff",
        color: state.isFocused ? "#fff" : "#000",
        fontSize: "14px",
        "&:hover": {
          backgroundColor: "#E84F6B",
          color: "#fff",
        },
      }),
      placeholder: (base) => ({
        ...base,
        color: "#aaa",
      }),
      multiValue: (base) => ({
        ...base,
        backgroundColor: "#E84F6B",
        color: "#fff",
      }),
      multiValueLabel: (base) => ({
        ...base,
        color: "#fff",
      }),
      multiValueRemove: (base) => ({
        ...base,
        color: "#fff",
        ":hover": { backgroundColor: "#E82347", color: "#fff" },
      }),
    };
  
    return (
      <Select
     
        isMulti ={isMulti}
        isClearable
        options={options}
        value={selectedOptions}
        onChange={handleChange}
        placeholder={placeholder || "Select options..."}
        styles={customStyles}
      />
    );
  };
  

export default MultiSelectDropdown;
