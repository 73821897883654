//////////survey  api

const survey = {
  surveys: () => `/survey`,
  get: () => `/survey/GetByToken`,
  getBySurveyId: () => `/survey/GetBySurveyId`,
  getAll: (cId, cType, limit) =>
    `/survey/GetAll?customerId=${cId}&customerType=${cType}&limit=${limit}`,
  submit: () => `/survey/SubmitSurvey`,
  updateParticipants: () => `/survey/UpdateParticipants`,
  getSurveyForResults: (schoolId, matId) =>
    `/survey/getSurveyForResults?schoolId=${schoolId}&matId=${matId}`,

  getSurveyDashboard: (surveyId, cType) =>
    `/survey/GetSurveyDashboard?surveyId=${surveyId}&customerType=${cType}`,
}



const scheduler = {
  canPublish: () => `/Scheduler/CanPublish`,
  publish: () => `/Scheduler/Publish`,
}

const surveytag = {
  surveyTags: (id, ctype) => `/surveytag?id=${id}&customertype=${ctype}`,
}

const artifact = {
  get: src => `/Artifact/download?fileName=${src}`,
  post: () => `/Artifact`,
}
const surveyQuestion = {
  post: () => `/surveyquestion`,
  put: () => `/surveyquestion`,
  delete: (id, surveyId) => `/surveyquestion?id=${id}&&surveyId=${surveyId}`,
  get: id => `/surveyquestion?id=${id}`,
  getByToken: () => `/surveyquestion/GetSurveyQuestionsBySurveyToken`,
  addSurveyQuestion: () => `/surveyquestion/AddSurveyQuestion`,
  addSurveyQuestionRemoveTempId: (surveyId, replaceQdId, newQid) =>
    `/surveyquestion/AddSurveyQuestionRemoveTempId?surveyId=${surveyId}&replaceQdId=${replaceQdId}&newQid=${newQid}`,
}

const participants = {
  getSchoolsByMatId: matId => `/Participants/GetMatSchools?id=${matId}`,
  getStudentParent: () => 'Participants/GetStudentParantFromPm',
  getStaff: () => 'Participants/GetStaffFromPm',
}

const groupCycles = {
  post: () => `/groupCycle`,
  getBySurveyToken: () => `/groupCycle/getBySurveyToken`,
}

const categories = {
  post: () => `/category`,
  get: () => `/category`,
}

const subcategory = {
  post: () => `/subcategory`,
  get: () => `/subcategory`,
}

const questions = {
  post: () => `/questions`,
  get: id => `/questions?id=${id}`,
  getBySubCatId: id => `/questions/getBySubCatId?id=${id}`,
  getQuestionForParticipants: () =>
    `/surveyquestion/GetSurveyQuestionByParticipantToken`,
  getListByIds: () => `/questions/GetListByIds`,
  getAll: id => `/questions/getAll`,
  getQuestionsByTagId: (tagId, audience) =>
    `/questions/GetQuestionsByTagId?tagId=${tagId}&audience=${audience}`,
}

const surveyTemplates = {
  get: (customerId, customerType) =>
    `/surveytemplates?id=${customerId}&customerType=${customerType}`,

  copyTemplate: (customerId, customerType, templateId, anonym, shared) =>
    `/surveytemplates/CopyTemplate?id=${customerId}&customerType=${customerType}&templateId=${templateId}&anonym=${anonym}&shared=${shared}`,
}

const comments = {
  get: (surveyId, questionId, schoolId) =>
    `/SurveyComments?surveyId=${surveyId}&questionId=${questionId}&schoolId=${schoolId}`,
  getCommenterName: id => `/SurveyComments/GetCommenterName?id=${id}`,
  AddCommentReply: () => `/SurveyComments/AddCommentReply`,
  GetRepliesByCommentId: id => `/SurveyComments/GetRepliesByCommentId?id=${id}`,
  getSentimentAnalysis: (surveyId, customerType, schoolId) =>
    `/SurveyComments/GetSentimentAnalysis?surveyId=${surveyId}&customerType=${customerType}&schoolId=${schoolId}`,
}

const eduIntell = {  
  //GetEDUIntell: (surveyId,userId, userQuery,requestType) => `/EDUIntell/GetEDUIntell?surveyId=${surveyId}&userId=${userId}&userQuery=${userQuery}&requestType=${requestType}`,
  GetEDUIntell: (surveyId,customerId,schoolId,customerType,fetchedDataset,userId, userQuery,requestType) => `/EDUIntell/GetEDUIntell?surveyId=${surveyId}&customerId=${customerId}&schoolId=${schoolId}&customerType=${customerType}&userId=${userId}&dataset=${fetchedDataset}&userQuery=${userQuery}&requestType=${requestType}`,
}

const looker = {  
  //GetEDUIntell: (surveyId,userId, userQuery,requestType) => `/EDUIntell/GetEDUIntell?surveyId=${surveyId}&userId=${userId}&userQuery=${userQuery}&requestType=${requestType}`,
  GenerateSignedLookerUrlsQuery: (customerId,selectedSurvey, customerType,datasetName, userId, firstName, lastName,isFirstLogin) => `/Looker/getSignedUrls?schoolId=${customerId}&surveyId=${selectedSurvey}&customerType=${customerType}&dataset=${datasetName}&externalUserId=${userId}&firstName=${firstName}&lastName=${lastName}&isFirstLogin=${isFirstLogin}`,
}

const lookerQuestion = {  
  //GetEDUIntell: (surveyId,userId, userQuery,requestType) => `/EDUIntell/GetEDUIntell?surveyId=${surveyId}&userId=${userId}&userQuery=${userQuery}&requestType=${requestType}`,
  GenerateSignedQuestionsLookerUrlsQuery: (customerId,selectedSurvey,questionId, customerType,datasetName, userId, firstName, lastName,isFirstLogin) => `/Looker/getSignedQuestionUrls?schoolId=${customerId}&surveyId=${selectedSurvey}&questionId=${questionId}&customerType=${customerType}&dataset=${datasetName}&externalUserId=${userId}&firstName=${firstName}&lastName=${lastName}&isFirstLogin=${isFirstLogin}`,
}

const lookerHeatmap = {  
  //GetEDUIntell: (surveyId,userId, userQuery,requestType) => `/EDUIntell/GetEDUIntell?surveyId=${surveyId}&userId=${userId}&userQuery=${userQuery}&requestType=${requestType}`,
  GenerateSignedHeatmapLookerUrlsQuery: (customerId,selectedSurvey, customerType,datasetName, userId, firstName, lastName,isFirstLogin) => `/Looker/getSignedHeatmapUrls?schoolId=${customerId}&surveyId=${selectedSurvey}&customerType=${customerType}&dataset=${datasetName}&externalUserId=${userId}&firstName=${firstName}&lastName=${lastName}&isFirstLogin=${isFirstLogin}`,
}


const eduIntellDeleteHistory = {  
  //GetEDUIntell: (surveyId,userId, userQuery,requestType) => `/EDUIntell/GetEDUIntell?surveyId=${surveyId}&userId=${userId}&userQuery=${userQuery}&requestType=${requestType}`,
 EDUIntellDeleteHistory: (surveyId,customerId,schoolId,customerType,userId) => `/EDUIntell/EDUIntellDeleteHistory?surveyId=${surveyId}&customerId=${customerId}&schoolId=${schoolId}&customerType=${customerType}&userId=${userId}`,
}

const getSurveyAnalysis = {  
 SurveyAnalysis: (groupId,schoolId,surveyId,filter) => `/SurveyAnalytics/GetSurveyAnalytics?groupId=${groupId}&schoolId=${schoolId}&surveyId=${surveyId}&filter=${filter}`,
}

const getSchoolsComparison = {  
  GeSchoolsComparison: (surveyId,groupId) => `/SurveyAnalytics/GeSchoolsComparison?surveyId=${surveyId}&groupId=${groupId}`,
 }
 



const tags = {
  get: () => `/tags`,
}

export {
  artifact,
  comments,
  groupCycles,
  participants,
  questions,
  scheduler,
  survey,
  eduIntell,
  eduIntellDeleteHistory,
  looker,
  lookerQuestion,
  lookerHeatmap,
  surveyQuestion,
  surveytag,
  surveyTemplates,
  tags,
  getSurveyAnalysis,
  getSchoolsComparison
}
