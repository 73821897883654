import request from '../../request'

import {handleApiError} from '../../../components/common/handleApiError'
import {getSchoolsComparison, getSurveyAnalysis, survey} from '../../endpoints'

export default class surveyAnalysisApi {
  static async GetSurveyAnalysis(groupId, schoolId, surveyId, filter) {
    try {
      return await request({
        url: getSurveyAnalysis.SurveyAnalysis(groupId, schoolId, surveyId, filter),
        method: 'GET',
      })
    } catch (error) {
      handleApiError(error, 'Failed to get surveys analysis.')
      throw error
    }
  }

  static async GetSchoolsComparison( surveyId, groupId) {
    try {
      return await request({
        url: getSchoolsComparison.GeSchoolsComparison(surveyId, groupId),
        method: 'GET',
      })
    } catch (error) {
      handleApiError(error, 'Failed to get surveys school comparison analysis.')
      throw error
    }
  }
}



