import React, {useEffect, useState} from 'react'
import {
  Container,
  Grid,
  Stack,
  Chip,
  MenuItem,
  TextField,
  Alert,
  Card,
  CardContent,
  Typography,
  Divider,
  Tab,
  Tabs,
} from '@mui/material'
import Header from '../../components/layouts/header/Header'
// import WbTextField from '../../components/common/WbTextField'
import WbFloatButton from '../../components/common/WbFloatButton'

import useCustomerData from '../../hooks/useCustomerData'

import useUserData from '../../hooks/useUserData'

import WbLoader from '../../components/common/WbLoader'
import {useMutateGetAllSurveys} from '../../services/api/surveys/hooks/useGetAllSurveys'
import {useMutateGetSurveyDetails} from '../../services/api/surveys/hooks/useGetSurveyDetails'
//import {Error} from '@mui/icons-material'
import {useLoader} from '../../components/common/WEBLoaderProvider'
import {useGenerateLookerUrls} from '../../services/api/looker/hooks/useGenerateLookerUrls'
import {toast} from 'react-toastify'
import {handleApiError} from '../../components/common/handleApiError'
// import detectIncognito from '../../utils/detectIncognito'
import {useMutateGetSurveyAnalysis} from '../../services/api/surveys/hooks/useGetSurveyAnalysis'
import {ColumnChartRecharts, HeatmapChart, GroupedBarChart, GaugeChart} from '../../components/welbeecharts'

import MultiSelectDropdown from '../../components/common/MultiSelectDropdown'
import {useGetSchoolsComparison} from '../../services/api/surveys/hooks/useGetSchoolsComparison'
import {CommentsWithSentimentChart} from '../../components/welbeecharts/SentimentAnalysisChart'
import {useGetSentimentAnalysis} from '../../services/api/comments/hooks/useGetSentimentAnalysis'

//import $ from 'jquery';
// import queryString from 'query-string'
export const FilterObtions = [
  {id: 'job_role', name: 'Job Role'},
  {id: 'job_title', name: 'Job Title'},
  {id: 'department', name: 'Department'},
  {id: 'employment_type', name: 'Employment Types'},
]
const getDateString = (date, format) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const getPaddedComp = comp => (parseInt(comp, 10) < 10 ? `0${comp}` : comp)
  let formattedDate = format
  const o = {
    'y+': date.getFullYear(),
    'M+': months[date.getMonth()],
    'd+': getPaddedComp(date.getDate()),
    'h+': getPaddedComp(
      date.getHours() > 12 ? date.getHours() % 12 : date.getHours()
    ),
    'H+': getPaddedComp(date.getHours()),
    'm+': getPaddedComp(date.getMinutes()),
    's+': getPaddedComp(date.getSeconds()),
    'S+': getPaddedComp(date.getMilliseconds()),
    'b+': date.getHours() >= 12 ? 'PM' : 'AM',
  }

  for (const k in o) {
    if (new RegExp(`(${k})`).test(format)) {
      formattedDate = formattedDate.replace(RegExp.$1, o[k])
    }
  }
  return formattedDate
}

//setInterval(myTimer, 3000);
//function myTimer() {
//$(".dWJJOy").style.display='none';
// alert('Display changed');
//}

const getAudienceChip = audience => {
  switch (audience) {
    case 1:
      return <Chip className="audience-chip" label="Staff" size="small" />
    case 2:
      return <Chip className="audience-chip" label="Parents" size="small" />
    case 3:
      return (
        <>
          <Chip className="audience-chip" label="Staff" size="small" />
          <Chip className="audience-chip" label="Parent" size="small" />
        </>
      )
    case 4:
      return <Chip className="audience-chip" label="Students" size="small" />
    case 5:
      return (
        <>
          <Chip className="audience-chip" label="Students" size="small" />
          <Chip className="audience-chip" label="Staff" size="small" />
        </>
      )
    case 6:
      return (
        <>
          <Chip className="audience-chip" label="Students" size="small" />
          <Chip className="audience-chip" label="Parents" size="small" />
        </>
      )
    default:
      return (
        <>
          <Chip className="audience-chip" label="Staff" size="small" />
          <Chip className="audience-chip" label="Students" size="small" />
          <Chip className="audience-chip" label="Parents" size="small" />
        </>
      )
  }
}

export default function SurveyAnalysis() {
  const customer = useCustomerData()
  const user = useUserData()
  const {showLoader, hideLoader} = useLoader()
  const [selectedSchool, setSelectedSchool] = useState('')
  const [selectedSurveyId, setSelectedSurveyId] = useState('')
  const [schools, setSchools] = useState([])
  const [isIncognito, setIsIncognito] = useState(false)
  const [browserName, setBrowserName] = useState('')
  const [selectedFilter, setFilter] = useState('')
  const [selectedQuestion, setQuestion] = useState('')
  const [selectedTab, setSelectedTab] = useState(0)
  const [filteredQuestions, setFilteredQuestions] = useState([])
  const [filteredHeatmap, setFilteredHeatmap] = useState([])
  const [filterMetadata, setFilterMetadata] = useState([])
 

  const {
    mutateAsync: fetchAllSurveys,
    data: allSurvey,
    isLoading: isAllSurveyLoading,
    error: allSurveyError,
  } = useMutateGetAllSurveys()
  const {
    mutateAsync: fetchSurveyDetails,
    data: selectedSurveyData,
    isLoading: isDetailSurveyLoading,
    error: surveyDetailsError,
  } = useMutateGetSurveyDetails()
  const {
    mutateAsync: sendLookFetchMessage,
    data: surveyLooks,
    isLoading: isLooksLoading,
    error: lookerError,
  } = useGenerateLookerUrls()

  const {
    mutateAsync: mutateSurveyAnalysis,
    data: surveyAnalysis,
    isLoading: isSurveyAnalysisLoading,
    error: SurveyAnalysisError,
  } = useMutateGetSurveyAnalysis()

  const {
    mutateAsync: mutateSchoolComparison,
    data: schoolComparisonData,
    isLoading: isSchoolComparisonLoading,
    error: schoolComparisonError,
  } = useGetSchoolsComparison()

  const {
    mutateAsync: mutateSentimentAnalysis,
    data: sentimentAnalysisData,
    isLoading: isSentimentAnalysisLoading,
    error: sentimentAnalysisError,
  } = useGetSentimentAnalysis()

  const getSchoolsBySurveyId = (surveys, targetSurveyId = null) => {
    const surveyId =
      targetSurveyId || Math.max(...surveys.map(survey => survey.id))
    const selectedSurvey = surveys.find(survey => survey.id === surveyId)
    //return selectedSurvey?.schools || []
    const uniqueSchools = selectedSurvey?.schools.filter(
      (value, index, self) =>
        index === self.findIndex(t => t.schoolId === value.schoolId)
    )
    return uniqueSchools || []
  }

  useEffect(() => {
    var maxSurveyId = ''
    setSelectedTab(0)
    setFilter('')
    const search = window.location.search
    const params = new URLSearchParams(search)
    const querystringCycleId = params.get('CycleId')

    const fetchSurveys = async () => {
      try {
        const surveys = await fetchAllSurveys({
          customerId: customer.id,
          customerType: customer.customerType,
        })

        if (surveys.length === 0) {
          toast.info('No surveys available currently.')
          setSchools([])
          hideLoader()
          return
        }

        if (querystringCycleId !== null) {
          maxSurveyId = Math.max(...surveys.map(survey => querystringCycleId))
        } else {
          maxSurveyId = Math.max(...surveys.map(survey => survey.id))
        }
        setSelectedSurveyId(maxSurveyId)
        setSchools(getSchoolsBySurveyId(surveys, maxSurveyId))

        await mutateSurveyAnalysis({
          groupId: customer.customerType === 1 ? customer.id : null,
          schoolId: customer.customerType === 2 ? customer.id : null,
          surveyId: maxSurveyId,
          filter: '',
        })
      } catch (error) {
        handleApiError(error, 'APIs Error')
      }
    }

    if (customer?.id) {
      fetchSurveys()
    }
  }, [customer?.id, customer?.customerType])

  const handleSurveyChange = async e => {
    const selected = e.target.value

    setSelectedSurveyId(selected)
    const selectedSchools = getSchoolsBySurveyId(allSurvey, selected)

    setSchools(selectedSchools)

    await fetchSurveyDetails({
      surveyId: selected === undefined ? null : selected,
      cType: customer.customerType,
    })

    await mutateSurveyAnalysis({
      groupId: customer.customerType === 1 ? customer.id : null,
      schoolId: customer.customerType === 2 ? customer.id : null,
      surveyId: selected,
      filter: selectedFilter,
    })

    if (selectedTab === 1) {
      await mutateSchoolComparison({
        groupId: customer.customerType === 1 ? customer.id : null,
        surveyId: selected,
      })
    }
  }

  const handleSchoolComparisionChange = async e => {
    const selected = e.target.value
    await mutateSchoolComparison({
      groupId: customer.customerType === 1 ? customer.id : null,
      surveyId: selectedSurveyId,
    })
  }

  //surveyId: 1652,
  // customerType:2 ,  //customer.customerType,
  //schoolId:1464
  const handleSenetimenAnalysisChange = async e => {
    const selected = e.target.value
    await mutateSentimentAnalysis({
      surveyId: selectedSurveyId,
      customerType: customer.customerType,
      schoolId: customer.customerType === 2 ? customer.id : null,
    })
  }

  const handleFilterChange = async e => {
    const selected = e.target.value

    setFilter(selected)

    await mutateSurveyAnalysis({
      groupId: customer.customerType === 1 ? customer.id : null,
      schoolId: customer.customerType === 2 ? customer.id : null,
      surveyId: selectedSurveyId,
      filter: selected,
    })
  }

  const handleSchoolChange = async e => {
    const selectedSchoolId = e.target.value
    setSelectedSchool(selectedSchoolId)
    await mutateSurveyAnalysis({
      groupId: customer.customerType === 1 ? customer.id : null,
      schoolId: selectedSchoolId,
      surveyId: selectedSurveyId,
      filter: selectedFilter,
    })
  }
  const handleQuestionChange = async (selectedOption) => {
    if (!selectedOption || !selectedOption.value) return;
  
    const selectedQId = selectedOption.value;
  

    const serachHeatmap = surveyAnalysis?.heatmap?.find(
      (question) => question.questionNumber === selectedQId
    );

    const serachQuestion= surveyAnalysis?.questions?.find(
      (question) => question.questionNumber === selectedQId
    );

    setFilteredQuestions(serachQuestion || [])
    setFilteredHeatmap(serachHeatmap || [])
  };

  const handleDropdownSearchChange = selectedOptions => {
    const selectedFilters = selectedOptions?.map(option => option.value) || []
    let searchQuestions = []
    let serachHeatmap = []
    if (selectedTab === 1) {
      searchQuestions = schoolComparisonData?.questions || []
      serachHeatmap = schoolComparisonData?.heatmap || []
      const updatedSchoolMetadata = schoolComparisonData?.schoolMetaData.filter(
        item => selectedFilters.includes(item.school_name)
      )
      setFilterMetadata(updatedSchoolMetadata || [])
    } else {
      searchQuestions = surveyAnalysis?.questions || []
      serachHeatmap = surveyAnalysis?.heatmap || []
    }
    // Filter questions
    const updatedQuestions = searchQuestions.filter(question =>
      selectedFilters.includes(question.filterValue)
    )

    // Filter heatmap
    const updatedHeatmap = serachHeatmap.filter(heatmapItem =>
      selectedFilters.includes(heatmapItem.filterValue)
    )

    setFilteredQuestions(updatedQuestions || [])
    setFilteredHeatmap(updatedHeatmap || [])
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }
  console.log(customer);

  return (
    <>
      <WbLoader isLoader={isLooksLoading}>
        <Header />
        <>
          {!allSurvey || allSurvey?.length === 0 ? (
            <Grid container>
              <Alert severity="info" sx={{my: 2, mx: 2, width: '100%'}}>
                There are no surveys published or open currently. Please check
                back later.
              </Alert>
            </Grid>
          ) : (
            <div className="main-container insights-container">
              <Container maxWidth="lg">
                <Grid container spacing={2} alignItems="center" mt={2}>
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <MultiSelectDropdown
                      filterOptions={allSurvey?.map(survey => ({
                        id: survey.id,
                        name: survey.name,
                      }))}
                      placeholder="Select Survey"
                      isMulti={false}
                      onChange={selected =>
                        handleSurveyChange({
                          target: {value: selected?.value || ''},
                        })
                      }
                      defaultValue={
                        allSurvey?.length > 0
                          ? {
                              value: selectedSurveyId,
                              label: allSurvey.find(
                                survey => survey.id === selectedSurveyId
                              )?.name,
                            }
                          : null
                      }
                    />
                  </Grid>
                  {customer.customerType !== 2 && (
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <MultiSelectDropdown
                        filterOptions={[
                          {id: customer.Id, name: customer.name + ' (Group)'},
                          ...schools.map(school => ({
                            id: school.schoolId,
                            name: school.schoolName,
                          })),
                        ]}
                        placeholder="Select School"
                        isMulti={false}
                        onChange={selected =>
                          handleSchoolChange({
                            target: {
                              value: selected?.value || '',
                            },
                          })
                        }
                      />
                    </Grid>
                  )}
                  {selectedTab === 0 && (
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                      <MultiSelectDropdown
                        filterOptions={FilterObtions?.map(filter => ({
                          id: filter.id,
                          name: filter.name,
                        }))}
                        placeholder="Select Filter"
                        isMulti={false}
                        onChange={selected =>
                          handleFilterChange({
                            target: {value: selected?.value || ''},
                          })
                        }
                      />
                    </Grid>
                  )}
                  <Grid item lg={3} md={3} sm={12} xs={12}>
                    <MultiSelectDropdown
                      filterOptions={Array.from(
                        new Map(
                          surveyAnalysis?.heatmap?.map(heatmap => [
                            heatmap.questionNumber,
                            {
                              id: heatmap.questionNumber,
                              name: heatmap.question,
                            },
                          ])
                        ).values()
                      )}
                      placeholder="Select Question"
                      isMulti={false}
                      onChange={selected =>
                        handleQuestionChange({
                          target: {value: selected?.value || ''},
                        })
                      }
                    />
                  </Grid>
                </Grid>

                {/* Survey Details Card */}
                <Grid container mt={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {!surveyAnalysis?.surveyMetadata ? (
                      <Grid item lg={12} md={12} sm={12} xs={12} mt={8}>
                        <div className="body-container" style={{padding: '0'}}>
                          <Alert
                            severity="info"
                            sx={{my: 2, mx: 2, width: '96%'}}
                          >
                            The selected survey is either closed or not
                            available. Please select an active survey from the
                            dropdown.
                          </Alert>
                        </div>
                      </Grid>
                    ) : (
                      <Card
                        sx={{
                          backgroundColor: '#fff',
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                          borderRadius: 0,
                          padding: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <CardContent sx={{flex: 1, fontSize: '16px'}}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 'bold',
                              fontSize: '16px',
                              marginBottom: 1,
                            }}
                          >
                            {surveyAnalysis?.surveyMetadata?.survey_title ||
                              'Survey Details'}
                          </Typography>
                          <Stack spacing={1}>
                            <Typography>
                              <strong>Launch Date:</strong>{' '}
                              {surveyAnalysis?.surveyMetadata?.launch_date ||
                                'N/A'}
                            </Typography>
                            <Typography>
                              <strong>Close Date:</strong>{' '}
                              {surveyAnalysis?.surveyMetadata?.close_date ||
                                'N/A'}
                            </Typography>
                            <Typography>
                              <strong>School/Trust Name:</strong>{' '}
                              {surveyAnalysis?.surveyMetadata?.school_name ||
                                'N/A'}
                            </Typography>
                            <Typography>
                              <strong>Audience:</strong>{' '}
                              {surveyAnalysis?.surveyMetadata?.audiences ||
                                'N/A'}
                            </Typography>
                            <Typography>
                              <strong>Total Submissions:</strong>{' '}
                              {surveyAnalysis?.surveyMetadata
                                ?.total_submissions || 'N/A'}
                            </Typography>
                          </Stack>
                        </CardContent>
                        <Divider orientation="vertical" flexItem sx={{mx: 2}} />
                        <GaugeChart
                          participationRate={
                            surveyAnalysis?.surveyMetadata
                              ?.participation_rate || '0%'
                          }
                        />
                      </Card>
                    )}
                  </Grid>
                </Grid>

                {/* Tabs and Main Content */}
                <Grid container mt={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container alignItems="center">
                      <Grid
                        item
                        xs={selectedFilter || selectedTab === 1 ? 6 : 12}
                      >
                        <Tabs
                          value={selectedTab}
                          onChange={handleTabChange}
                          aria-label="Analysis Tabs"
                          sx={{borderBottom: 1, borderColor: 'divider'}}
                          TabIndicatorProps={{style: {display: 'none'}}}
                        >
                          <Tab
                            label="Overall Analysis"
                            sx={{
                              boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                              textTransform: 'none',
                              fontSize: 14,
                              fontFamily: 'Inter, sans-serif',
                              padding: '8px 15px',
                              border: '1px solid',
                              lineHeight: 1.5,
                              color: '#000',
                              backgroundColor:
                                selectedTab === 0 ? '#E84F6B' : '#e2e2e2',
                              borderColor: '#E84F6B',
                              borderRadius: '0',
                              '&:hover': {
                                backgroundColor: '#E82347',
                                borderColor: '#E82347',
                                boxShadow: 'none',
                                color: '#fff',
                              },
                              '&.Mui-selected': {
                                color: '#fff', // Ensure white text for the selected tab
                                backgroundColor: '#E84F6B',
                                borderColor: '#E84F6B',
                              },
                            }}
                          />
                          {customer.customerType === 1 && (
                            <Tab
                              label="School Comparison"
                              onClick={handleSchoolComparisionChange}
                              sx={{
                                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                                textTransform: 'none',
                                fontSize: 14,
                                fontFamily: 'Inter, sans-serif',
                                padding: '8px 15px',
                                border: '1px solid',
                                lineHeight: 1.5,
                                color: '#000',
                                backgroundColor:
                                  selectedTab === 1 ? '#E84F6B' : '#e2e2e2',
                                borderColor: '#E84F6B',
                                borderRadius: '0',
                                '&:hover': {
                                  backgroundColor: '#E82347',
                                  borderColor: '#E82347',
                                  boxShadow: 'none',
                                  color: '#fff',
                                },
                                '&.Mui-selected': {
                                  color: '#fff', // Ensure white text for the selected tab
                                  backgroundColor: '#E84F6B',
                                  borderColor: '#E84F6B',
                                },
                              }}
                            />
                          )}

                          <Tab
                            label="Sentiment Analysis"
                            onClick={handleSenetimenAnalysisChange}
                            sx={{
                              textTransform: 'none',
                              fontSize: 14,
                              fontFamily: 'Inter, sans-serif',
                              padding: '8px 15px',
                              border: '1px solid',
                              lineHeight: 1.5,
                              boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                              color: '#000',
                              backgroundColor:
                                selectedTab === 2 ? '#E84F6B' : '#e2e2e2',
                              borderColor: '#E84F6B',
                              borderRadius: '0',
                              '&:hover': {
                                backgroundColor: '#E82347',
                                borderColor: '#E82347',
                                boxShadow: 'none',
                                color: '#fff',
                              },
                              '&.Mui-selected': {
                                color: '#fff', // Ensure white text for the selected tab
                                backgroundColor: '#E84F6B',
                                borderColor: '#E84F6B',
                              },
                            }}
                          />
                        </Tabs>
                      </Grid>
                      {(selectedFilter || selectedTab === 1) && (
                        <Grid item xs={6}>
                          <MultiSelectDropdown
                            filterOptions={
                              selectedTab === 1
                                ? schoolComparisonData?.filtersValues
                                : surveyAnalysis?.filtersValues
                            }
                            placeholder="Search and select filter value..."
                            onChange={handleDropdownSearchChange}
                          />
                        </Grid>
                      )}
                    </Grid>

                    {/* Tab Content  */}
                    {selectedTab === 0 && (
                      // Overall Analysis Tab Content
                      <>
                        {!isSurveyAnalysisLoading && (
                          <>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <HeatmapChart
                                heatmapData={
                                  filteredHeatmap.length > 0
                                    ? filteredHeatmap
                                    : surveyAnalysis?.heatmap || []
                                }
                              />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                              {selectedFilter ? (
                                <GroupedBarChart
                                  questions={
                                    filteredQuestions.length > 0
                                      ? filteredQuestions
                                      : surveyAnalysis?.questions || []
                                  }
                                />
                              ) : (
                                <ColumnChartRecharts
                                  surveyData={
                                    filteredQuestions.length > 0
                                      ? filteredQuestions
                                      : surveyAnalysis?.questions || []
                                  }
                                />
                              )}
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    {selectedTab === 1 && (
                      <div>
                        {/* Tab Content  School Comparison */}
                        {/* Heatmap Section */}
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <HeatmapChart
                            heatmapData={
                              filteredHeatmap.length > 0
                                ? filteredHeatmap
                                : schoolComparisonData?.heatmap || []
                            }
                          />
                        </Grid>

                        {/* School Comparison Cards */}
                        <Grid container spacing={2} mt={2}>
                          {(filterMetadata?.length > 0
                            ? filterMetadata
                            : schoolComparisonData?.schoolMetaData
                          )?.map((school, index) => (
                            <Grid item lg={3} md={3} sm={6} xs={12} key={index}>
                              <Card
                                sx={{
                                  backgroundColor: '#fff',
                                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                  borderRadius: '0px',
                                  padding: '16px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  height: '100%',
                                }}
                              >
                                <CardContent>
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontWeight: 'bold',
                                      fontSize: '16px',
                                      marginBottom: '8px',
                                    }}
                                  >
                                    {school.school_name || 'School Name'}
                                  </Typography>
                                  <Typography>
                                    <strong>Survey Title:</strong>{' '}
                                    {school.survey_title || 'N/A'}
                                  </Typography>
                                  <Typography>
                                    <strong>Launch Date:</strong>{' '}
                                    {school.launch_date
                                      ? new Date(
                                          school.launch_date
                                        ).toLocaleDateString()
                                      : 'N/A'}
                                  </Typography>
                                  <Typography>
                                    <strong>Close Date:</strong>{' '}
                                    {school.close_date
                                      ? new Date(
                                          school.close_date
                                        ).toLocaleDateString()
                                      : 'N/A'}
                                  </Typography>
                                  <Typography>
                                    <strong>Audience:</strong>{' '}
                                    {school.audiences || 'N/A'}
                                  </Typography>
                                  <Typography>
                                    <strong>Total Submissions:</strong>{' '}
                                    {school.total_submissions || '0'}
                                  </Typography>
                                </CardContent>
                                <Divider sx={{my: 2}} />
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center', // Horizontally center the gauge
                                    justifyContent: 'center', // Vertically center the gauge
                                    textAlign: 'center',
                                  }}
                                >
                                  <GaugeChart
                                    width={200}
                                    height={200}
                                    participationRate={
                                      school.participation_rate || '0%'
                                    }
                                    size={150} // Adjust size as needed
                                  />
                                </div>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>

                        {/* Grouped Bar Chart */}
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                          <GroupedBarChart
                            questions={
                              filteredQuestions.length > 0
                                ? filteredQuestions
                                : schoolComparisonData?.questions || []
                            }
                          />
                        </Grid>
                      </div>
                    )}

                    {selectedTab === 2 && (
                      // Sentiment Analysis Tab Content
                      <Typography variant="body1">
                        {!isSentimentAnalysisLoading && (
                          <CommentsWithSentimentChart
                            sentimentData={sentimentAnalysisData}
                          />
                        )}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </div>
          )}
          {!isAllSurveyLoading && !isDetailSurveyLoading && !isLooksLoading && (
            <WbFloatButton
              surveyInfo={{
                surveyId: selectedSurveyId,
                customerId: customer.id,
                schoolId: selectedSchool,
                customerType: customer.customerType,
                datasetval:
                  customer.customerType === 1
                    ? 'Group' + customer.id
                    : 'Individual' + customer.id,
              }}
            />
          )}
        </>
      </WbLoader>
    </>
  )
}
