import { useMutation } from "@tanstack/react-query";
import { useLoader } from "../../../../components/common/WEBLoaderProvider";

import { handleApiError } from "../../../../components/common/handleApiError";

import commentsApi from "../commentsApi";

export const useGetSentimentAnalysis = () => {
    const { hideLoader} = useLoader()
  return useMutation(
    ({ surveyId,customerType,schoolId}) => commentsApi.GetSentimentAnalysis(surveyId,customerType,schoolId),
    {
      onError: (error) => {
        hideLoader();
        handleApiError(error, 'Failed to load selected survey.');
      },
  
      retry:false
    }
  );
};
