import request from '../../request'
import {lookerQuestion} from '../../endpoints'
import { handleApiError } from '../../../components/common/handleApiError';

export default class lookerQuestionAPI {  
  static async GenerateSignedQuestionsLookerUrlsQuery(schoolId,surveyId,questionId,CustomerType,fetchedDataset,userId, firstName,lastName,isFirstLogin) {
    try {
      return await request({
        url: lookerQuestion.GenerateSignedQuestionsLookerUrlsQuery(schoolId,surveyId,questionId,CustomerType,fetchedDataset,userId, firstName,lastName,isFirstLogin),
        method: 'GET',
      })
    } catch (error) {
      handleApiError(error, 'Failed to get Looker data.');
      throw error;
    }
   
  }
}
