import React, {useEffect, useState} from 'react'
import {
  Container,
  Grid,
  Stack,
  Chip,
  MenuItem,
  TextField,
  Alert,
} from '@mui/material'
import Header from '../../components/layouts/header/Header'
import WbTextField from '../../components/common/WbTextField'
import useCustomerData from '../../hooks/useCustomerData'
import useUserData from '../../hooks/useUserData'
import SurveyQuestionsDetailsCharts from '../../components/common/SurveyQuestionsDetailsCharts'
import WbLoader from '../../components/common/WbLoader'
import {useMutateGetAllSurveys} from '../../services/api/surveys/hooks/useGetAllSurveys'
import {useMutateGetSurveyDetails} from '../../services/api/surveys/hooks/useGetSurveyDetails'
//import {Error} from '@mui/icons-material'
import {useLoader} from '../../components/common/WEBLoaderProvider'
// import {useGenerateLookerUrls} from '../../services/api/looker/hooks/useGenerateLookerUrls'
import {useGenerateQuestionLookerUrls} from '../../services/api/looker/hooks/useGenerateQuestionLookerUrls'
import {toast} from 'react-toastify'
import {handleApiError} from '../../components/common/handleApiError'
import detectIncognito from '../../utils/detectIncognito'
//import $ from 'jquery';
// import queryString from 'query-string'

const getDateString = (date, format) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const getPaddedComp = comp => (parseInt(comp, 10) < 10 ? `0${comp}` : comp)
  let formattedDate = format
  const o = {
    'y+': date.getFullYear(),
    'M+': months[date.getMonth()],
    'd+': getPaddedComp(date.getDate()),
    'h+': getPaddedComp(
      date.getHours() > 12 ? date.getHours() % 12 : date.getHours()
    ),
    'H+': getPaddedComp(date.getHours()),
    'm+': getPaddedComp(date.getMinutes()),
    's+': getPaddedComp(date.getSeconds()),
    'S+': getPaddedComp(date.getMilliseconds()),
    'b+': date.getHours() >= 12 ? 'PM' : 'AM',
  }

  for (const k in o) {
    if (new RegExp(`(${k})`).test(format)) {
      formattedDate = formattedDate.replace(RegExp.$1, o[k])
    }
  }
  return formattedDate
}

//setInterval(myTimer, 3000);
//function myTimer() {  
  //$(".dWJJOy").style.display='none';
  // alert('Display changed');
//}

const getAudienceChip = audience => {
  switch (audience) {
    case 1:
      return <Chip className="audience-chip" label="Staff" size="small" />
    case 2:
      return <Chip className="audience-chip" label="Parents" size="small" />
    case 3:
      return (
        <>
          <Chip className="audience-chip" label="Staff" size="small" />
          <Chip className="audience-chip" label="Parent" size="small" />
        </>
      )
    case 4:
      return <Chip className="audience-chip" label="Students" size="small" />
    case 5:
      return (
        <>
          <Chip className="audience-chip" label="Students" size="small" />
          <Chip className="audience-chip" label="Staff" size="small" />
        </>
      )
    case 6:
      return (
        <>
          <Chip className="audience-chip" label="Students" size="small" />
          <Chip className="audience-chip" label="Parents" size="small" />
        </>
      )
    default:
      return (
        <>
          <Chip className="audience-chip" label="Staff" size="small" />
          <Chip className="audience-chip" label="Students" size="small" />
          <Chip className="audience-chip" label="Parents" size="small" />
        </>
      )
  }
}

export default function EDUQuestionsDashboard() {
  const customer = useCustomerData()
  const user = useUserData()
  const {showLoader, hideLoader} = useLoader()
  const [selectedSchool, setSelectedSchool] = useState('')
  const [selectedSurveyId, setSelectedSurveyId] = useState('')
  const [schools, setSchools] = useState([])
  const [selectedQuestionId, setSelectedQuestionId] = useState('')
  const [questions, setQuestions] = useState([])
  const [isIncognito, setIsIncognito] = useState(false);
  const [browserName, setBrowserName] = useState('');

  const {
    mutateAsync: fetchAllSurveys,
    data: allSurvey,
    isLoading: isAllSurveyLoading,
    error: allSurveyError,
  } = useMutateGetAllSurveys()
  const {
    mutateAsync: fetchSurveyDetails,
    data: selectedSurveyData,
    isLoading: isDetailSurveyLoading,
    error: surveyDetailsError,
  } = useMutateGetSurveyDetails()
  const {
    mutateAsync: sendLookFetchMessage,
    data: surveyLooks,
    isLoading: isLooksLoading,
    error: lookerError,
  } = useGenerateQuestionLookerUrls()

  const getSchoolsBySurveyId = (surveys, targetSurveyId = null) => {   
    const surveyId = targetSurveyId || Math.max(...surveys.map(survey => survey.id))    
    const selectedSurvey = surveys.find(survey => survey.id === surveyId)
    //return selectedSurvey?.schools || []         
    const uniqueSchools = selectedSurvey?.schools.filter((value, index, self) =>
      index === self.findIndex((t) => (
          t.schoolId === value.schoolId
      ))
    )
    return uniqueSchools || []   
  }

  const getQuestionsBySurveyId = (surveys, targetSurveyId = null) => {   
    const surveyId = targetSurveyId || Math.max(...surveys.map(survey => survey.id))    
    const selectedSurvey = surveys.find(survey => survey.id === surveyId)   
    return selectedSurvey?.questions || []         
    // const uniqueQuestions = selectedSurvey?.questions.filter((value, index, self) =>
    //   index === self.findIndex((t) => (
    //       t.questionId === value.questionId
    //   ))
    // )
    // return uniqueQuestions || []   
  }

  useEffect(() => { 
    var maxSurveyId ="";
    var maxQuestionId ="";    
     // Add the Query String check for parametters CycleId
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const querystringCycleId = params.get('CycleId');      
    const querystringQuestionId = params.get('QuestionId'); 
    // console.log('querystringCycleId',querystringCycleId);
    // Add the Query String check for parametters CycleId
    const fetchSurveys = async () => {
      try {
        const surveys = await fetchAllSurveys({
          customerId: customer.id,
          customerType: customer.customerType,
        })

        if (surveys.length === 0) {
          toast.info('No surveys available currently.')
          setSchools([])
          hideLoader()
          return
        }      
        
        if(querystringCycleId !==null)
        {
          maxSurveyId = Math.max(...surveys.map(survey => querystringCycleId));         
        }
        else
        {
          maxSurveyId = Math.max(...surveys.map(survey => survey.id))         
        }
        setSelectedSurveyId(maxSurveyId)             
        setSchools(getSchoolsBySurveyId(surveys, maxSurveyId))     
        setQuestions(getQuestionsBySurveyId(surveys, maxSurveyId))             

        //alert('selected Question ID '+questions[0].questionId)    
        if(querystringQuestionId !==null)
        {
          maxQuestionId = querystringQuestionId;         
        }
        else
        {                   
          maxQuestionId = Math.max(...questions.map(question => question.questionId));//questions[0].questionId;         
        }      
        //maxQuestionId = Math.max(...questions.map(question => question.questionId))           
        setSelectedQuestionId(maxQuestionId) 
        // maxQuestionId = questions[0].questionId;    
         //setSelectedQuestionId(742);  
        await fetchSurveyDetails({
          surveyId: maxSurveyId,
          cType: customer.customerType,
        })  
        await sendLookFetchMessage({
          selectedSurvey: maxSurveyId,
          customerId: customer.customerType === 1 ? null : customer.id,
          questionId : 1209,//selectedQuestionId,
          customerType: customer.customerType,
          datasetName:   customer.customerType === 1 ? "Group"+customer.id : "Individual"+customer.id, //customer.customerDataset,
          userId: user.Id,
          firstName: customer.name,
          lastName: '',
          isFirstLogin: true
        })
        setSelectedQuestionId(1209)  
      } catch (error) {
        handleApiError(error, 'APIs Error')
      }
    }

    if (customer?.id) {
      fetchSurveys()
    }
  }, [customer?.id, customer?.customerType])

  const handleSurveyChange = async e => {
    const selected = e.target.value
    var maxQuestionId ="";   
    setSelectedSurveyId(selected)
    const selectedSchools = getSchoolsBySurveyId(allSurvey, selected)
    const selectedQuestions =getQuestionsBySurveyId(allSurvey, selected)        
    setSchools(selectedSchools)
    setQuestions(selectedQuestions)  
    maxQuestionId = Math.max(...questions.map(question => question.questionId))           
    setSelectedQuestionId(maxQuestionId)   
    await fetchSurveyDetails({
      surveyId: selected=== undefined ? null : selected,
      cType: customer.customerType,
    })
    console.log("Customer Data : "+customer);
    await sendLookFetchMessage({
      selectedSurvey: selected === undefined ? null : selected,
      customerId: customer.customerType === 1 ? null : customer.id,
      questionId : maxQuestionId,
      customerType: customer.customerType,
      datasetName:  customer.customerType === 1 ? "Group"+customer.id : "Individual"+customer.id, //customer.customerDataset, 
      userId: user.Id,
      firstName: customer.name,
      lastName: '',
      isFirstLogin: false
    })
  }

  const handleSchoolChange = async e => {    
    const selected = e.target.value       
    //var maxQuestionId ="";   
    setSelectedSchool(selected)   
    // maxQuestionId = Math.max(...questions.map(question => question.questionId))           
    // setSelectedQuestionId(maxQuestionId) 
    // alert(maxQuestionId)
    await sendLookFetchMessage({
      selectedSurvey:   selectedSurveyId === undefined ? null : selectedSurveyId,
      customerId:  selected === undefined ? null : selected,
      questionId : selectedQuestionId,
      customerType: customer.customerType,
      datasetName:  customer.customerType === 1 ? "Group"+customer.id : "Individual"+customer.id, //customer.customerDataset,
      userId: user.Id,
      firstName: customer.name,
      lastName: '',
      isFirstLogin: false
    })
  }

  
  const handleQuestionChange = async e => {  
    const selected = e.target.value   
    setSelectedQuestionId(selected)   
    await fetchSurveyDetails({
      surveyId: selectedSurveyId,
      cType: customer.customerType,
    })   
    await sendLookFetchMessage({
      selectedSurvey:   selectedSurveyId === undefined ? null : selectedSurveyId,
      customerId:  selectedSchool === undefined ? null : selectedSchool,
      questionId : selected === undefined ? null : selected,
      customerType: customer.customerType,
      datasetName:  customer.customerType === 1 ? "Group"+customer.id : "Individual"+customer.id, //customer.customerDataset,
      userId: user.Id,
      firstName: customer.name,
      lastName: '',
      isFirstLogin: false
    })
  }

  
  useEffect(() => {
    const checkIncognito = async () => {
      try {
        const { isPrivate, browserName } = await detectIncognito();
        if (browserName === 'Chrome' || browserName === 'Safari') {
          setIsIncognito(isPrivate);
        }
        setBrowserName(browserName);
        console.log(`Browser: ${browserName}, Incognito: ${isPrivate}`);
      } catch (error) {
        console.error('Failed to detect incognito mode:', error);
      }
    };
    checkIncognito();
  }, []);

  console.log('Incognito:', surveyLooks);
  return (
    <>
      <WbLoader isLoader={isLooksLoading}>
        <Header />
        <>
          {!allSurvey || allSurvey?.length === 0 ? (
            <Grid container>
              <Alert severity="info" sx={{my: 2, mx: 2, width: '100%'}}>
                There are no surveys published or open currently. Please check
                back later
              </Alert>
            </Grid>
          ) : (
            <div className="main-container insights-container">
              <Container maxWidth="lg">
                <Grid container mt={3}>
                  <Grid item lg={4} md={7} sm={6} xs={12}>
                    <h1>Latest Survey</h1>
                    {!surveyLooks || surveyLooks?.questionUrls?.length ===0? (                        
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={8}>
                         <div className="body-container" style={{padding: '0'}}>
                        <Alert severity="info"  sx={{my: 2, mx: 2, width: '96%'}} >                       
                          The selected survey is either closed or not available.
                          Please select an active survey from the dropdown.
                        </Alert>
                        </div>
                        </Grid>                  
                    ) : (
                      <Stack direction="column" mt={4}>
                        <h2>{selectedSurveyData?.name}</h2>
                        <Stack direction="row" alignItems="center" my={2}>
                          Audience:
                          <Stack direction="row" spacing={0.5} ml={0.5}>
                            {getAudienceChip(selectedSurveyData?.audience)}
                          </Stack>
                        </Stack>
                        <div className="launch-date">
                          Launch date/time:{' '}
                          <span>
                            {getDateString(
                              new Date(selectedSurveyData?.createdAt),
                              'M d, y at h:m b'
                            )}
                          </span>
                        </div>
                        <div className="launch-date">
                          End date/time:{' '}
                          <span>
                            {getDateString(
                              new Date(selectedSurveyData?.createdAt),
                              'M d, y at h:m b'
                            )}
                          </span>
                        </div>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item lg={8} md={5} sm={6} xs={12}>
                    <Grid container spacing={1}>
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <WbTextField
                          id="SurveyDropDown"
                          label="Select Survey"
                          onChange={handleSurveyChange}
                          select
                          value={selectedSurveyId}
                        >
                          {allSurvey?.map(result => (
                            <MenuItem key={result?.id} value={result?.id}>
                              {result?.name}
                            </MenuItem>
                          ))}
                        </WbTextField>
                      </Grid>
                      {customer.customerType !== 2 && (
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <WbTextField
                            label="Select School"
                            select
                            onChange={handleSchoolChange}
                            value={selectedSchool}
                          >
                            <MenuItem
                                key={customer.Id}
                                value={customer.Id}
                              >
                                {customer.name + " (Group)"} 
                              </MenuItem>
                            {schools?.map(school => (
                              <MenuItem
                                key={school?.schoolId}
                                value={school?.schoolId}
                              >
                                {school?.schoolName}
                              </MenuItem>
                            ))}                         
                          </WbTextField>
                        </Grid>
                      )}
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                          <WbTextField
                            id="QuestionDropDown"
                            label="Select Question"
                            select
                            onChange={handleQuestionChange}
                            value={selectedQuestionId}                                                       
                          >     
                          {questions?.map(result => (
                            <MenuItem key={result?.questionId} value={result?.questionId}>
                              {'Q'+result?.questionOrder}
                            </MenuItem>
                          ))}                         
                          </WbTextField>
                        </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div>
                {isIncognito? (
                <div className="body-container" style={{padding: '0' , marginTop: '5px'}} >
                  <Alert severity="warning" sx={{ width: '100%' }}>
                    It looks like you are browsing in Incognito or Private mode or have third party cookies disabled.  Your survey results are currently unable to load in these modes. Please exit Incognito or Private mode to continue.
                  </Alert>
                  </div>      
                    ):(<SurveyQuestionsDetailsCharts surveyData={surveyLooks?.questionUrls} isFirstLogin={surveyLooks?.isFirstLogin} />)}                  
                </div>
              </Container>
            </div>
          )}         
        </>
      </WbLoader>
    </>
  )
}
